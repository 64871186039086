import React, { useEffect, useState, useRef } from "react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import CloseIcon from "@mui/icons-material/Close";
import ApolloContextProvider from "../../../GraphQL/ApolloContextProvider";
import MainCard from "../../../components/common/MainCard";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { literals } from "../../../enums/literalCodes";
import "./FeeCalendar.scss"
import { GET_TIME_ENTRIES_BY_DATE,POST_TIME_ENTRIES } from "../graphql/mutations/feeCalendar";
import { GET_TIME_ENTRIES_BY_DATE_RANGE } from "../graphql/queries/feeCalendar";
import { useMutation, useQuery } from '@apollo/client';
import { Calendar } from '@fullcalendar/core';
import jsUtils from "../../../utils/jsUtils";
import FeeCalendarUtils from "./utils";
import Entries from "./TimeEntries";
import SummarySection from "./SummarySection";
import multiMonthPlugin from '@fullcalendar/multimonth';
import TimeEntryModal from "../TimeEntryGrid/TimeEntryModal"
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { loggedInUser } from "../../../redux-toolkit/slices/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
import StyledLoadingButton from "../../../components/common/StyledLoadingButton";
import { openSnackbar } from '../../../redux-toolkit/slices/snackbar';
import AlertDialog from '../../../components/common/AlertDialog';

const InitialSummary = {
  adminHours: null,
  billable: null,
  billableAmount: null,
  noOfEntries: null,
  nonBillable: null,
  totalHours: null
}

export default function FeeCalendar({ removeWidgetFromDashboard, widgetId }) {

  const [dateRange, setDateRange] = useState({})
  const calendarRef = useRef(null);
  const eventSourceRef = useRef([]);
  const [viewType, setViewType] = useState("grouping") // grouping = client/matter view, list = list of time entries for month/day
  const [entries, setEntriesData] = useState([])
  const [dailyEntries, setDailyEntriesData] = useState([])
  const [monthSummary, setMonthSummary] = useState(InitialSummary);
  const [yearlySummary, setYearlySummary] = useState({});
  const [yearlyEntries, setYearlyEntries] = useState([]);
  const [dailySummary, setDailySummary] = useState(InitialSummary);
  const selectedDateRef = useRef('');
  const calendarViewRef = useRef({type: 'month', isMonthOrday: 'month'}); // month or year types
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 

  //creating id by Date.now() as we did in the Dashboard widgets for save/post first time.
  const [activityId, setActivityID] = useState(null);
  const [activityDate, setActivityDate] = useState(null);
  const [isOpenFromcalender, setIsOpenFromcalender] = useState(true);
  const [isOpenFromModel, setIsOpenFromModel] = useState(false);
  const [Modal, setModal] = useState(null);

  // Maintain a reference to the previously selected day element
  const prevSelectedDayElRef = useRef(null);
  const { employeeId } = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!employeeId) {
      dispatch(loggedInUser());
    }
  }, []);

  const [getTimeEntriesByDate, { data: dateData }] = useMutation(GET_TIME_ENTRIES_BY_DATE, {
    variables: {
      timeEntrySearchRequest: { date: selectedDateRef.current, toDate: selectedDateRef.current, timekeeperId: localStorage.getItem('empId') || employeeId }
    }
  });

  const [postAll, { data: postData, loading : isPosting, error : postError }] = useMutation(POST_TIME_ENTRIES);

  // YEAR/MONTH DATA ENTRIES  
  const { data, refetch} = useQuery(GET_TIME_ENTRIES_BY_DATE_RANGE, {
      variables: {
        feeCalendarRequest: {...dateRange, employeeId:localStorage.getItem('empId') || employeeId}
      }
    });

  useEffect(() => {
    refetch();
  }, [dateRange])

  useEffect(() => {
    if(data){
      const {feeCalDays, summary, timeEntriesByCM} = data.getFeeCalendarDetails;
      const { events } = FeeCalendarUtils.getCalendarEvents(feeCalDays, 'entryDate');
      updateEvents(events);

      const { entries } = FeeCalendarUtils.getEntriesByRange(timeEntriesByCM);
      const summaryObject =  {
        noOfEntries: summary?.count || 0,
        billable: summary?.billableHours  || 0,
        nonBillable: summary?.nonBillableHours || 0,
        totalHours: summary?.totalHours || 0,
        billableAmount: summary?.totalAmount || 0,
        adminHours: 0
      }

      if (calendarViewRef.current.type == 'month') {
        setEntriesData(entries);
        setMonthSummary(summaryObject);
      }
      else if (calendarViewRef.current.type == 'year') {
        setYearlyEntries(entries)
        setYearlySummary(summaryObject);
      }
      setIsLoading(false);
    }
  }, [data])

  useEffect(() => {
    //Fetching selected Day Data...
    if (dateData) {
      const { summary } = FeeCalendarUtils.getSummary(dateData.search.content);
      setDailySummary(summary);
      const { entries } = FeeCalendarUtils.getMonthEntries(dateData.search.content);
      setDailyEntriesData(entries)
      setIsLoading(false);
    }
  }, [dateData])


  const updateEvents = (newEvents) => {
    eventSourceRef.current = newEvents;
    const calendar = calendarRef.current;
    if (calendar) {
      calendar.setOption('events', newEvents);
    }
  };

  const handleDateClick = (selectedDateStr, selectedDayEl) => {

    // Check if there was a previously selected day
    if (prevSelectedDayElRef.current) {
      // Remove background color from the previously selected day
      prevSelectedDayElRef.current.style.backgroundColor = '';
    }

    if (selectedDateRef.current === selectedDateStr) {
      // Date clicked again, remove background color
      selectedDayEl.style.backgroundColor = '';
      selectedDateRef.current = '';

      calendarViewRef.current ={type: calendarViewRef.current.type, isMonthOrday: 'month'};
      setDailySummary({});
      setDailyEntriesData([])

      //resetting states for the time entry modal.
      setActivityDate(null)
    } else {
      // New date clicked, change background color
      selectedDayEl.style.backgroundColor = '#8CD2FA99';
      selectedDateRef.current = selectedDateStr;

      //setting date to show on the field of TE on the time entry modal.
      setActivityDate(selectedDateStr)
      //When there is no event on the selected day, show the Month view.
      if (FeeCalendarUtils.hasNoEvent(selectedDayEl.textContent)) {
        calendarViewRef.current = {type: calendarViewRef.current.type, isMonthOrday: 'month'}
      }
      else {
        setIsLoading(true);
        calendarViewRef.current = {type: calendarViewRef.current.type, isMonthOrday: 'day'}
        setDailySummary({});
        getTimeEntriesByDate();
        setViewType("grouping");
      }
    }

    prevSelectedDayElRef.current = selectedDayEl;
  };

  const resetStates = () => {
    setMonthSummary(InitialSummary);
    setYearlySummary(InitialSummary);
    setDailySummary(InitialSummary);
    setEntriesData([]);
    setYearlyEntries([]);
    setDailyEntriesData([]);
    setIsLoading(true);
    setViewType("grouping");
  }

  useEffect(() => {
    const calendarEl = calendarRef.current;
    const calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin , multiMonthPlugin, interactionPlugin],
      headerToolbar: {
        left: 'prev title next',
        center: '',
        right: 'dayGridMonth,multiMonthYear'
      },
      eventOrder: true,
      events: eventSourceRef.current || [],
      dayHeaderFormat: {
        weekday: 'short'
      },
      multiMonthMaxColumns: 2,
      showNonCurrentDates: false,        
      datesSet: function (info) {
        calendar.removeAllEvents(); // Clear existing events
        resetStates();

        // Find all month title elements and attach a click event listener
        const monthTitles = calendarEl.querySelectorAll('.fc-multimonth-title');
        const yearTitle = calendarEl.querySelector('.fc-toolbar-title');

        monthTitles.forEach((monthTitle) => {
          monthTitle.addEventListener('click', () => {
            const clickedMonth = monthTitle.textContent.trim(); // Trim whitespace
            const currentYear = yearTitle.textContent.trim();

            // Convert month name to month number
            const monthNumber = new Date(`${clickedMonth} 1, 2000`).getMonth() + 1;

            // Navigate to the clicked month view
            calendar.gotoDate(`${currentYear}-${monthNumber.toString().padStart(2, '0')}`);
            calendar.changeView('dayGridMonth');
          });
        });

        const {view } = info;
        const {type} = view;
       
        const currentDate = calendar.getDate();
        setDateRange(prev => {
          if( type === 'multiMonthYear'){
            calendarViewRef.current ={type: 'year', isMonthOrday: 'month'};
            return jsUtils.getYearRange((currentDate.getMonth() + 1), currentDate.getFullYear())
          }
          else{ 
            calendarViewRef.current ={type: 'month', isMonthOrday: 'month'};
            return jsUtils.getMonthRange((currentDate.getMonth() + 1), currentDate.getFullYear())
          }
        })
      },
      eventClick: function (info) {
        const dateStr = info.event.startStr;

        // Find the day element associated with the event
        const dayElements = document.querySelectorAll('.fc-day');

        dayElements.forEach((dayElement) => {
          const dateAttribute = dayElement.getAttribute('data-date');
          if (dateAttribute === dateStr) {
            // Call the handleDateClick function with the day element
            handleDateClick(dateStr, dayElement);
          }
        });
      },
      dateClick: function (info) {
        handleDateClick(info.dateStr, info.dayEl);
      }
    });

    calendar.render();
    calendarRef.current = calendar;

    return () => {
      calendar.destroy();
    };

  }, []);

  const AddNewTimeEntry = () => {
    setActivityID(null);
    setIsOpenFromcalender(true);
    setIsOpenFromModel(false);
    setOpen(true);
  }

  const disablePostAll = () => {
    if((yearlyEntries.length > 0 || entries.length > 0 || dailyEntries.length > 0 ) && !isPosting){
      return false;
    }
    return true;
  }

  const GetSecondaryComponents = () => {
    return (
      <div className="secondaryItems">
        <Tooltip title={'Post All'}>
          <StyledLoadingButton disabled={disablePostAll()} onClick={() => !disablePostAll() && onPostAll()} id={literals.POST_ALL} variant="contained" style={{ backgroundColor: "#0050C3" }} >{literals.POST_ALL}</StyledLoadingButton>
        </Tooltip>
        <Tooltip title={'Add Time Entry'}>
          <IconButton onClick={() => AddNewTimeEntry()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Settings'}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={literals.REMOVE_WIDGET}>
          <IconButton onClick={() => removeWidgetFromDashboard(widgetId)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        {Modal !== null && <AlertDialog {...Modal} />}
      </div>
    );
  };

  const handleEditModalClose = () => {
    
    //Closing the time entry Modal
    setOpen(false);

    //Resetting the state.
    setActivityID(null);
    setIsOpenFromcalender(true);
    setIsOpenFromModel(false);
    
    //Recalling the APIs
    getTimeEntriesByDate()
    refetch();
   };

  const handleTimeEntry = (activityID) => {
    setActivityID(activityID);
    setIsOpenFromcalender(false);
    setIsOpenFromModel(true);
    setOpen(true);
  };

  const returnSummary = () => {
    if(calendarViewRef.current.type == 'month' && calendarViewRef.current.isMonthOrday == 'month'){
      return monthSummary;
    }
    else if(calendarViewRef.current.type == 'year' && calendarViewRef.current.isMonthOrday == 'month'){
      return yearlySummary;
    }
    else{
      return dailySummary;
    }
  }

  const returnDataEntries = () => {
    if(calendarViewRef.current.type == 'month' && calendarViewRef.current.isMonthOrday == 'month'){
      return entries;
    }
    else if(calendarViewRef.current.type == 'year' && calendarViewRef.current.isMonthOrday == 'month'){
      return yearlyEntries;
    }
    else{
      return dailyEntries;
    }
  }

  const postAllChanges = async () => {
    setModal(null);
    try {
      await postAll({ variables: { req: getPostAllPayload() } });
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const onDiscardModal = () => {
    setModal(null);
  };

  const onPostAll = () => {
    setModal({
      open: true,
      title: literals.POST_ALL_TIME_ENTRIES_HEADER,
      message: literals.POST_ALL_TIME_ENTRIES_MESSAGE,
      cancelLabel: '',
      discardLabel: literals.NO,
      confirmLabel: literals.YES,
      onConfirm: postAllChanges,
      onDiscard: onDiscardModal,
      onCancel: onDiscardModal,
      ModalType: literals.THREE_ACTIONS,
    });
  }

  useEffect(() => {
    if (postData) {
      const { results } = postData.postAllCalendarEntries;

      if(results){
        const totalEntries = results.length;
        const Posted = results.filter((entries) => entries.timeEntryDto).length;
        const NotPosted = totalEntries - Posted;
    
        const message = `${totalEntries} Time Entries Found. ${Posted} Posted. ${NotPosted} Not Posted.`;
    
        dispatch(
          openSnackbar({
            message: message,
            severity: literals.SUCCESS,
          })
        );
      }
      else{
        dispatch(
          openSnackbar({
            message: literals.SOMETHING_WENT_WRONG,
            severity: literals.ERROR,
          })
        );
      }
    }

  }, [postData]);
  

  const getPostAllPayload = () => {
    const { current: calendarView } = calendarViewRef;
    const { isMonthOrday } = calendarView || {};
    
    const fromDate = isMonthOrday === "day" ? selectedDateRef.current : dateRange.fromDate;
    const toDate = isMonthOrday === "day" ? selectedDateRef.current : dateRange.toDate;
  
    return { employeeId, fromDate, toDate };
  };

  return (
    <ApolloContextProvider uri="/time-management/graphql">
      <MainCard
        className="fee-calendar-main-card"
        title={literals.MY_CALENDAR}
        secondary={GetSecondaryComponents()}
      >
        <Grid container>
          <Grid xs={8} md={9} item>
            <div ref={calendarRef}></div>
          </Grid>
          <Grid xs={4} md={3} item>
            <SummarySection summary={returnSummary()} calView={calendarViewRef.current} selectedDate={selectedDateRef.current} />
            <Entries 
              data={returnDataEntries()} 
              viewType={viewType} 
              calView={calendarViewRef.current}
              calCurrentView={calendarViewRef}
              setViewType={v => setViewType(v)}
              handleTimeEntry={handleTimeEntry}
              isLoading={isLoading}
              yearlyDateRange= {{...dateRange, employeeId}}
            />
          </Grid>
        </Grid>
      </MainCard>
      
      {open && <TimeEntryModal
        open={open}
        isOpenFromModel={isOpenFromModel}
        isOpenFromcalender={isOpenFromcalender}
        widgetId ={isOpenFromcalender ? Date.now() : null}
        handleClose={handleEditModalClose}
        selectedTimeEntry={activityId}
        activityDate={activityDate}
      />}
    </ApolloContextProvider>
  );
}