import './TimeEntryDataGrid.scss';

import {
  DataGridPro,
  GridActionsCellItem,
  GridRowModes,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ToggleCheckStateForChanges,
  ToggleTimeEntryTabs,
  ToggleWantedToMove,
} from '../../../redux-toolkit/slices/DashboardTabs';
import { useDispatch, useSelector } from 'react-redux';

import AlertDialog from '../../../components/common/AlertDialog';
import ApolloContextProvider from '../../../GraphQL/ApolloContextProvider.js';
import { Box } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import ColumnVisibility from './GridVIsibilityColumns.js';
import CustomGridToolBar from './CustomGridToolBar';
import CustomPaginationTimeEntryGrid from '../../../components/CustomPagination/CustomPaginationTimeEntryGrid';
import INITIALSTATE from './GridInitialState';
import { LicenseInfo } from '@mui/x-license-pro';
import LinearProgress from '@mui/material/LinearProgress';
import TimeEntryModal from './TimeEntryModal';
import { columnsDef } from './TimeEntryGridColumns.js';
import dayjs from 'dayjs';
import { fetchTimeEntryList } from '../../../redux-toolkit/slices/timeEntries';
import { getColumns } from './TimeEntryGridColumnDef.js';
import jsUtils from '../../../utils/jsUtils.js';
import { literals } from '../../../enums/literalCodes';
import { openSnackbar } from '../../../redux-toolkit/slices/snackbar';
import { timeEntryColumns } from '../../../enums/timeEntryColumns';
import { timeEntryModel } from '../../../models/timeEntryModel';
import timeEntryService from '../../../services/timeEntryService';

LicenseInfo.setLicenseKey(
  '48ec2ee37f2a8c7b5049fd7177b37db9Tz04NzcxOSxFPTE3NDM3MTIxODEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const MIN_SECONDS = 360;
var isSubmit = null;
var selectedRowForAutoSave = null;

const TimeEntryGrid = ({ removeWidgetFromDashboard, widgetId }) => {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [editRowId, setEditRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState(
    []
  );
  const [Modal, setModal] = useState(null);
  const [timeColumns, settimeEntryColumns] =
    useState(timeEntryColumns);
  const DataGridRef = useRef(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [autoSaveTimer, setAutoSaveTimer] = useState(0);
  const autoSaveIntervalRef = useRef(null);
  const [timers, setTimers] = useState({});
  const [columnValues, setColumnValues] = useState({});
  const dispatch = useDispatch();
  const { timeEntries, page, totalPages } = useSelector(
    (state) => state.timeEntry
  );
  const [isLoading, setisLoading] = useState(false);
  //Initial columns for the toggle columns card
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState(ColumnVisibility);
  const { checkStatesForChanges, wantedToMove } = useSelector(
    (state) => state.tab
  );
  const ref = useRef();

  //storing column width into the state.
  const [columnWidths, setColumnWidths] = useState({});

  //Edit Modal States
  const [open, setOpen] = useState(false);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState({});
  const [entryTypeList, setEntryTypeList] = useState([]);
  const autoCompleteInitialStates = {
    clientAutocomplete: false,
    matterAutocomplete: false,
    timekeeperAutocomplete: false,
    activityAutocomplete: false,
    taskAutocomplete: false,
    locationAutocomplete: false,
  };

  const [autoCompleteFields, setAutoCompleteFields] = useState(
    autoCompleteInitialStates
  );
  const errors = {};
  const [previousTime, setPreviousTime] = useState(0);
  const OptionDescription = (option) =>
    (option?.code ? option.code + ' - ' : '') +
    (option.description || '');
  const OptionTimekeeperDescription = (option) =>
    option.description || '';
  const [timersActive, setTimersActive] = useState([]);

  const getEntryTypeList = () => {
    timeEntryService
      .getEntryTypes()
      .then((data) => {
        if (data.data.getEntryTypes) {
          setEntryTypeList(data.data.getEntryTypes);
        } else {
          setEntryTypeList([]);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    getEntryTypeList();
    if (timeEntries?.length <= 0 || !timeEntries) {
      setisLoading(true);

      dispatch(fetchTimeEntryList({ page: 0, size: 100 }))
        .then(() => {
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    timeEntries && setRows(timeEntries);
    setisLoading(false);
  }, [timeEntries]);

  useEffect(() => {
    setisLoading(true);
    timeEntries?.length > 0 && timeEntries && fetchEntries();
  }, [page]);

  const fetchEntries = () => {
    dispatch(fetchTimeEntryList({ page: page }));
  };

  const handleEditModalClose = () => {
    setOpen(false);
  };

  const getRowsValues = (params, column) => {
    const valuesObject = columnValues[params.id];

    if (valuesObject && valuesObject[column] !== undefined) {
      return valuesObject[column];
    }

    return params.row[column];
  };

  const handleCellValueChange = async (event, field, params) => {
    const { value } = event.target;

    const updatedColumnValues = {
      ...columnValues,
      [params.id]: {
        ...columnValues[params.id],
        [field]: value,
      },
    };

    if (field === 'type' && value === 'Task Fee') {
      const Rate = await getRateByTaskCode(params.row);

      const quantity = parseFloat(params.row.quantity) || 0;
      const amount = (quantity * Rate).toFixed(2);
      setRows(
        rows.map((obj) =>
          obj.id === params.id
            ? { ...obj, [field]: value, rate: Rate || 0, amount }
            : obj
        )
      );
    } else {
      setRows(
        rows.map((obj) =>
          obj.id === params.id ? { ...obj, [field]: value } : obj
        )
      );
    }

    setColumnValues(updatedColumnValues);
    handleRowEditStart(params);

    const autoCompleteFields = {
      clientMattersDescription: 'clientAutocomplete',
      matterName: 'matterAutocomplete',
      timekeeper: 'timekeeperAutocomplete',
      activity: 'activityAutocomplete',
      taskCode: 'taskAutocomplete',
      location: 'locationAutocomplete',
    };

    if (autoCompleteFields.hasOwnProperty(field)) {
      const fieldName = autoCompleteFields[field];
      setAutoCompleteFields((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    }
  };

  const setOptionsChanges = async (options, field, params) => {
    if (!options) {
      const autoCompleteFields = {
        clientMattersDescription: 'clientAutocomplete',
        matterName: 'matterAutocomplete',
        timekeeper: 'timekeeperAutocomplete',
        activity: 'activityAutocomplete',
        taskCode: 'taskAutocomplete',
        location: 'locationAutocomplete',
      };

      if (autoCompleteFields.hasOwnProperty(field)) {
        const fieldName = autoCompleteFields[field];
        setAutoCompleteFields((prevState) => ({
          ...prevState,
          [fieldName]: false,
        }));
      }
      return;
    }

    let newValue;
    let fieldName;
    switch (field) {
      case 'clientMattersDescription': {
        let { billableType } = options;
        fieldName = 'description';
        let { billable, billableCode } =
          jsUtils.getBillableInfo(billableType);
        newValue = {
          clientId: parseInt(options.clientId),
          customerId: parseInt(options.matterId),
          billable,
          billableCode,
        };
        break;
      }

      case 'matterName':
        fieldName = 'matterDesc';
        newValue = { customerId: parseInt(options.matterId) };
        break;

      case 'timekeeper':
        fieldName = 'description';
        newValue = { employeeId: parseInt(options.id) };
        break;

      case 'activity':
        fieldName = 'description';
        newValue = { activityCodeId: parseInt(options.id) };
        break;

      case 'taskCode':
        fieldName = 'description';
        newValue = { taskCodeId: parseInt(options.id) };
        break;

      case 'location':
        fieldName = 'description';
        newValue = { geoLocationId: parseInt(options.id) };
        break;

      default:
        return;
    }

    setColumnValues((prevValues) => ({
      ...prevValues,
      [params.id]: {
        ...prevValues[params.id],
        [field]:
          field === 'activity' ||
          field === 'taskCode' ||
          field === 'location'
            ? OptionDescription(options)
            : options[fieldName],
        ...newValue,
      },
    }));

    if (
      (field === 'type' || field === 'taskCode') &&
      params.row.type === 'Task Fee'
    ) {
      const Rate = await getRateByTaskCode(params.row);
      const quantity = parseFloat(params.row.quantity) || 0;
      const amount = (quantity * Rate).toFixed(2);

      setRows(
        rows.map((obj) =>
          obj.id === params.id
            ? {
                ...obj,
                ...newValue,
                [field]: options[fieldName],
                rate: Rate || 0,
                amount,
              }
            : obj
        )
      );
    } else {
      setRows(
        rows.map((obj) =>
          obj.id === params.id
            ? { ...obj, ...newValue, [field]: options[fieldName] }
            : obj
        )
      );
    }

    handleRowEditStart(params);
    setAutoCompleteFields(autoCompleteInitialStates);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [JSON.parse(JSON.stringify(rows))]);

  useEffect(() => {
    window.onbeforeunload = function () {
      let row = rows.filter(
        (r) => r?.id == selectedRowForAutoSave.id
      )[0];
      if (
        !jsUtils.checkIfObjectsEqual(selectedRowForAutoSave, row) &&
        selectedRowForAutoSave != null
      ) {
        return 'leave/refresh';
      } else {
        return;
      }
    };
  }, [rows, selectedRowForAutoSave]);

  useEffect(() => {
    // on tab changing if states have un saved data
    if (checkStatesForChanges) {
      clearInterval(autoSaveIntervalRef.current);

      setModal({
        open: true,
        title: 'Save unsaved changes',
        message: 'Are you sure you want to save changes?',
        cancelLabel: literals.DISCARD,
        discardLabel: literals.SAVE,
        confirmLabel: '',
        onCancel: onCancelModal,
        onDiscard: saveChanges,
        ModalType: literals.TWO_ACTIONS,
      });
    }
  }, [checkStatesForChanges]);

  const saveChanges = () => {
    setModal(null);
    setRowModesModel({});
    let newRow = rows.filter(
      (r) => r.id == selectedRowForAutoSave.id
    )[0];
    isSubmit = literals.SAVE;
    processRowUpdate(newRow);
  };

  const onCancelModal = (modal) => {
    //It will close the modal
    setModal(null);

    if (modal !== 'cancelIconModal') {
      isSubmit = null;
      resetAutoSaveStorage();
      selectedRowForAutoSave = null;

      if (checkStatesForChanges && wantedToMove) {
        // reset states
        dispatch(ToggleCheckStateForChanges(false)); // on changing tab , updated state that is useable in finding unSaved changes
        dispatch(ToggleWantedToMove('')); // store clicked tab while auto saving

        dispatch(ToggleTimeEntryTabs(wantedToMove));
      }
    }
  };

  //Setting the columns checkboxes according to the visibility selection
  const handleColumnVisibilityChange = useCallback((field) => {
    settimeEntryColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.field === field
          ? { ...column, isVisible: !column.isVisible }
          : column
      )
    );

    toggleColumnVisibility(field);
  }, []);

  //Reset the columns according to the visibility selection

  const toggleColumnVisibility = (columnName) => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      [columnName]: !prevModel[columnName],
    }));
  };

  const handleRowModes = (params, type) => {
    let updatedRow = rows.filter(
      (r) => r?.id == selectedRowForAutoSave?.id
    )[0];

    // Check if have un-saved changes
    if (
      selectedRowForAutoSave?.id != params.row?.id &&
      selectedRowForAutoSave != null &&
      !jsUtils.checkIfObjectsEqual(
        selectedRowForAutoSave,
        updatedRow
      ) &&
      updatedRow &&
      selectedRowForAutoSave?.id
    ) {
      clearInterval(autoSaveIntervalRef.current);

      setModal({
        open: true,
        title: 'Save unsaved changes',
        message: 'Are you sure you want to save changes?',
        cancelLabel: literals.DISCARD,
        discardLabel: literals.SAVE,
        confirmLabel: '',
        onCancel: onCancelModal,
        onDiscard: saveChanges,
        ModalType: literals.TWO_ACTIONS,
      });
    }

    var modes = { ...rowModesModel };

    // if (Object.keys(modes).length > 0) {
    //   Object.keys(modes)?.map((key) => {
    //     key == params.id
    //       ? (modes[key] = { mode: modes[key].mode })
    //       : (modes[key] = { mode: GridRowModes.View });
    //   });
    //   // modes[key] = {mode: GridRowModes.View}
    // }

    if (type === 'doubleClicked') {
      modes = { ...modes, [params.id]: { mode: GridRowModes.Edit } };
    }
    setRowModesModel(modes);
  };

  const handleRowEditStart = (params) => {
    if (
      params.row.status !== literals.POSTED &&
      params.row.id !== editRowId
    ) {
      handleRowModes(params, 'doubleClicked');
      setEditRowId(params.row.id);
      setSelectedRow(params.row);
    }
  };

  const startAutoSaveTimer = (row) => {
    let updatedRow = rows.filter(
      (r) => r?.id == selectedRowForAutoSave?.id
    )[0];

    if (
      selectedRowForAutoSave?.id != row?.id &&
      selectedRowForAutoSave != null &&
      !jsUtils.checkIfObjectsEqual(
        selectedRowForAutoSave,
        updatedRow
      ) &&
      updatedRow &&
      selectedRowForAutoSave?.id
    ) {
      setModal({
        open: true,
        title: 'Save unsaved changes',
        message: 'Are you sure you want to save changes?',
        cancelLabel: literals.DISCARD,
        discardLabel: literals.SAVE,
        confirmLabel: '',
        onCancel: onCancelModal,
        onDiscard: saveChanges,
        ModalType: literals.TWO_ACTIONS,
      });
    } else {
      if (
        selectedRowForAutoSave == null ||
        selectedRowForAutoSave?.id != row?.id
      ) {
        // if not already setted
        selectedRowForAutoSave = row;
        setSelectedRow(row);
        setEditRowId(row.id);

        clearInterval(autoSaveIntervalRef.current);

        autoSaveIntervalRef.current = setInterval(() => {
          setAutoSaveTimer((prevTime) => prevTime + 10);
        }, 10000);
      }
    }
  };

  const handleRowEditStop = (event) => {
    event.defaultMuiPrevented = true;
    // setEditRowId(null);
  };

  const handleEditClick = (row) => () => {
    setOpen(true);
    setSelectedTimeEntry(row.activityId);
    setPreviousTime(row.time);

    // Remove un-necessary states
    resetAutoSaveStorage();
    setRowModesModel({});
  };

  const handleCatch = (e) => {
    dispatch(
      openSnackbar({
        message: literals.SOMETHING_WENT_WRONG,
        severity: literals.ERROR,
      })
    );
    console.error(e);
  };

  const handleonSubmit = (id, action) => () => {
    isSubmit = action;
    let row = rows.find((item) => item.id === editRowId);
    if (columnValues[id]) {
      row = jsUtils.mapObjectToModel(row, columnValues[id]);
    }
    onSubmit(row, action);
  };

  const closeModal = () => {
    setModal(null);
  };

  const onDelete = (rowIds) => {
    const id = rowIds[0];

    if (id?.toString().includes('-')) {
      // newly added row, not yet added in DB
      onDiscardModal(literals.TWO_ACTIONS);
      dispatch(
        openSnackbar({
          message: literals.TIME_ENTRY_DELETED,
          severity: literals.SUCCESS,
        })
      );
    } else {
      timeEntryService
        .deleteTimeEntry(rowIds)
        .then((res) => {
          if (res?.data?.deleteTimeEntry) {
            var toastMesssage =
              rowIds.length === 1
                ? literals.TIME_ENTRY_DELETED
                : literals.TIME_ENTRIES_DELETED;
            closeModal();
            fetchEntries();
            dispatch(
              openSnackbar({
                message: toastMesssage,
                severity: literals.SUCCESS,
              })
            );
          } else {
            dispatch(
              openSnackbar({
                message: literals.SOMETHING_WENT_WRONG,
                severity: literals.ERROR,
              })
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onSubmit = async (row, saveType) => {
    //Mapping the row to the Model object that was required by API calls

    var ObjectModel = jsUtils.mapObjectToModel(INITIALSTATE, row);
    const foundItem = entryTypeList.find(
      (entry) => entry.description === row.type
    );
    ObjectModel.activityType = foundItem.code;
    //Converting the date format to YYYY-MM-DD required ny API calls
    delete ObjectModel.date;

    //INITIAL VALIDATION MODEL FOR SAVE ACTION
    var RowsToBeValidate = {
      client: row.clientId,
      matter: row.customerId,
      timekeeper: row.employeeId,
    };
    ObjectModel.isPost = false;

    //IF ACTION IS POST THEN WE WILL APPEND THE VALIDATION MODEL FOR POST
    if (isSubmit === literals.POST) {
      RowsToBeValidate.description = ObjectModel.description;
      RowsToBeValidate.hours = ObjectModel.hours;
      ObjectModel.isPost = true;
    }

    if (jsUtils.RequiredFieldsValidation(RowsToBeValidate)) {
      ObjectModel.quantity = ObjectModel.quantity;
      ObjectModel.hours =
        ObjectModel.hours == '' ? 0 : ObjectModel.hours;
      ObjectModel.hours = parseFloat(ObjectModel.hours);
      ObjectModel.activityDate = dayjs(row.date).format('YYYY-MM-DD');

      ObjectModel = jsUtils.mapObjectToModel(
        timeEntryModel,
        ObjectModel
      );
      ObjectModel.timerEventRequests = jsUtils.getTimerDto(widgetId);

      let serviceFunction;

      if (row.activityId) {
        if (saveType === literals.POST) {
          serviceFunction = timeEntryService.postTimeEntry;
        } else if (row?.status === literals.POSTED) {
          serviceFunction = timeEntryService.savePostedTimeEntry;
        } else {
          serviceFunction = timeEntryService.updateTimeEntry;
        }
        ObjectModel.activityId = row.activityId;
      } else {
        if (saveType === literals.POST) {
          serviceFunction = timeEntryService.postTimeEntry;
        } else {
          serviceFunction = timeEntryService.addTimeEntry;
          ObjectModel.timerEventRequests =
            jsUtils.getTimerDto(widgetId);
        }
      }

      try {
        const { data } = await serviceFunction(ObjectModel);
        const { timeEntryDto } = getResponse(data, saveType, row);
        handleResponse(timeEntryDto, row, saveType);
      } catch (e) {
        handleCatch(e);
        console.error(e);
      }
      isSubmit = null;
    } else {
      dispatch(
        openSnackbar({
          message: 'some fields are empty.',
          severity: literals.ERROR,
        })
      );
    }
  };

  const getResponse = (data, saveType, row) => {
    if (saveType === literals.POST) {
      return data.postTimeEntry;
    } else if (row?.status === literals.POSTED) {
      return data.savePostedTimeEntry;
    } else {
      return data.addUpdateTimeEntryDraft;
    }
  };

  const handleResponse = (timeEntryDto, row, saveType) => {
    if (timeEntryDto) {
      dispatch(
        openSnackbar({
          message:
            timeEntryDto.statusId === 3
              ? literals.TIME_ENTRY_POSTED
              : literals.TIME_ENTRY_SAVED,
          severity: literals.SUCCESS,
        })
      );

      const modifiedRows = rows.map((obj) => {
        if (obj.id === row.id) {
          return {
            ...obj,
            activityId: timeEntryDto.activityId,
            status: jsUtils.returnStatusCode(timeEntryDto.statusId),
            amount: timeEntryDto.amount,
            quantity: timeEntryDto.quantity,
            rate: timeEntryDto.rate,
          };
        }
        return obj;
      });
      setRows(modifiedRows);
      var modes = { ...rowModesModel };
      if (row?.id) {
        setRowModesModel({
          ...rowModesModel,
          [row.id]: { mode: GridRowModes.View },
        });
        // delete modes[row.id];
        setEditRowId(null);
      } else {
        setRowModesModel({
          ...rowModesModel,
          [timeEntryDto.activityId]: { mode: GridRowModes.View },
        });
        setEditRowId(null);
        // delete modes[timeEntryDto?.activityId];
      }
      fetchEntries();
    } else {
      dispatch(
        openSnackbar({
          message:
            saveType === literals.POST
              ? literals.TIME_ENTRY_CANT_POSTED
              : literals.TIME_ENTRY_CANT_SAVED,
          severity: literals.ERROR,
        })
      );
    }
  };

  const handleDeleteClick = (ids) => {
    setModal({
      open: true,
      title:
        ids.length === 1
          ? literals.DELETE_TIME_ENTRY
          : literals.DELETE_TIME_ENTRIES,
      message:
        ids.length === 1
          ? literals.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TIME_ENTRY
          : literals.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TIME_ENTRIES,
      cancelLabel: literals.GO_BACK,
      discardLabel: literals.DELETE,
      confirmLabel: '',
      rowId: ids,
      onCancel: closeModal,
      onDiscard: onDelete,
      ModalType: literals.TWO_ACTIONS,
    });
  };

  const handleAddtoHours = (Id, newTime) => {
    let params = rows.find((row) => row.id === Id);
    const newObj = { ...params };
    newObj.newTime = newTime;
    processRowUpdate(newObj, 'pause');
  };

  const handleDate = async (currentRow, date) => {
    let editRowDetails = rows.find((item) => item.id === editRowId);
    let updatedRow = {
      ...editRowDetails,
      date,
      activityDate: date !== null ? date.format('YYYY-MM-DD') : null,
    };

    if (editRowDetails.type === 'Task Fee') {
      const Rate = await getRateByTaskCode(editRowDetails);
      const quantity = parseFloat(editRowDetails.quantity) || 0;
      const amount = (quantity * Rate).toFixed(2);
      updatedRow = { ...updatedRow, rate: Rate || 0, amount };
    }

    setRows(
      rows.map((row) => (row.id === currentRow.id ? updatedRow : row))
    );
  };

  const getRateByTaskCode = async (row) => {
    try {
      const data = await timeEntryService.getRateByTaskCode({
        taskCodeId: row.taskCodeId,
        jobId: row.customerId,
        activityDate: dayjs(row.activityDate).format('YYYY-MM-DD'),
      });
      return data?.rate;
    } catch (error) {
      return 0; // Return a default value or handle the error as needed
    }
  };

  const processRowUpdate = async (newRow, cellName) => {
    var updatedRow = {};
    if (cellName === 'pause') {
      const updatedTime =
        newRow.newTime < MIN_SECONDS ? MIN_SECONDS : newRow.newTime;
      const updatedHours = parseFloat(newRow.hours || 0);
      const AccumulatedHours =
        updatedHours +
        parseFloat(jsUtils.secondIntoHours(updatedTime));

      newRow.time = AccumulatedHours.toFixed(2);
      newRow.hours = AccumulatedHours.toFixed(2);

      if (newRow.type === 'Time Fee') {
        newRow.amount = jsUtils.GetAmountByDuration(newRow.hours);
        newRow.quantity = newRow.hours;
      } else if (newRow.type === 'Simple Fee') {
        newRow.amount = jsUtils.GetTotalAmount(newRow);
      }
    }
    if (cellName == 'hours') {
      let hours = newRow.hours;
      var hoursToSeconds = jsUtils.hoursToSeconds(
        hours === '.' ? '.0' : hours
      );
      newRow.time = hoursToSeconds;

      var timer = timers[newRow.id];

      if (typeof timer !== 'undefined') {
        setTimers((prevTimers) => ({
          ...prevTimers,
          [newRow.id]: {
            ...prevTimers[newRow.id],
            time: hoursToSeconds,
          },
        }));
      }
    }

    if (isSubmit === literals.AUTO_SAVE) {
      let currentRow = rows.filter((r) => r.id == newRow.id)[0]; // FInd updated row of current row - selected for Edit
      updatedRow = { ...currentRow, isNew: false };
    } else {
      updatedRow = { ...newRow, isNew: false };
      selectedRowForAutoSave = {
        ...selectedRowForAutoSave,
        isNew: false,
      };

      // (JSON.stringify(selectedRowForAutoSave) !== JSON.stringify(updatedRow) && selectedRowForAutoSave != null) && localStorage.setItem(literals.AUTO_SAVE, "yes")
    }

    if (isSubmit !== null) {
      //INITIAL VALIDATION MODEL FOR SAVE ACTION
      var RowsToBeValidate = {
        client: updatedRow.matterName,
        matter: updatedRow.clientName,
        description: updatedRow.description,
        hours: updatedRow.hours,
      };

      if (jsUtils.RequiredFieldsValidation(RowsToBeValidate)) {
        if (
          isSubmit === literals.SAVE ||
          isSubmit === literals.AUTO_SAVE
        ) {
          // updatedRow.status = literals.DRAFT_READY_TO_POST
        } else {
          updatedRow.status = literals.POSTED;
        }
      }
    }
    setRows(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    if (isSubmit !== null) {
      selectedRowForAutoSave = updatedRow;
      setSelectedRow(updatedRow);
      if (isSubmit == literals.SAVE || isSubmit == literals.POST) {
        clearInterval(autoSaveIntervalRef.current);
        selectedRowForAutoSave = null;
        setSelectedRow(null);
      }

      localStorage.removeItem(literals.AUTO_SAVE);
    }
    isSubmit = null;
    return updatedRow;
  };

  const handleHours = (event) => {
    var { value } = event?.target;
    let editRowDetails = rows.find((item) => item.id === editRowId);
    if (jsUtils.validateHourInput(value)) {
      let row = { ...editRowDetails };
      row.hours = value;
      if (row.type === 'Simple Fee') {
        row.amount = jsUtils.GetTotalAmount({
          hours: value === '.' ? '.0' : value,
          rate: row.rate,
        });
        row.quantity = value;
      } else if (row.type === 'Time Fee') {
        row.quantity = value;
        row.amount = (row.quantity * row.rate).toFixed(2);
      } else if (row.type === 'Task Fee') {
        const quantity = row.quantity || 0;
        row.amount = (quantity * row.rate).toFixed(2);
      }
      processRowUpdate(row, 'hours');
    }
  };

  const handleQuantity = async (event) => {
    var { value } = event?.target;
    let editRowDetails = rows.find((item) => item.id === editRowId);
    if (!isNaN(value) && editRowDetails.type === 'Task Fee') {
      const Rate = await getRateByTaskCode(editRowDetails);
      const quantity = parseFloat(value) || 0;
      const amount = (quantity * Rate).toFixed(2);
      const row = {
        ...editRowDetails,
        quantity,
        amount,
        rate: Rate || 0,
      };
      processRowUpdate(row, 'quantity');
    }
  };

  const handleAmountEdit = async (event) => {
    var { value } = event?.target;
    let editRowDetails = rows.find((item) => item.id === editRowId);
    if (editRowDetails.type === 'Simple Fee') {
      editRowDetails.amount = value;
      processRowUpdate(editRowDetails, 'amount');
    }
  };

  const postMultipleRows = () => {
    const selectedIDs = new Set(rowSelectionModel);

    var selectedRows = rows.filter((row) => selectedIDs.has(row.id));

    selectedRows = selectedRows.map((row) => ({
      ...row,
      isPost: true,
    }));
    var timeEntries = [];

    for (var i = 0; i < selectedRows.length; i++) {
      var ObjectModel = jsUtils.mapObjectToModel(
        timeEntryModel,
        selectedRows[i]
      );
      ObjectModel.activityId = selectedRows[i].activityId;
      // delete ObjectModel["activityDate"] // for some entries , date is invalid that cause error with API so deleting this key.

      timeEntries.push(ObjectModel);
    }

    timeEntryService
      .updateMultipleTimeEntry(timeEntries)
      .then((res) => {
        if (res?.data?.updateMultipleTimeEntry) {
          if (timeEntries?.length > 1) {
            dispatch(
              openSnackbar({
                message: literals.TIME_ENTRIES_POSTED,
                severity: literals.SUCCESS,
              })
            );
          } else {
            dispatch(
              openSnackbar({
                message: literals.TIME_ENTRY_POSTED,
                severity: literals.SUCCESS,
              })
            );
          }

          setRowSelectionModel([]);
          fetchEntries();
        }
      })
      .catch((e) => {
        dispatch(
          openSnackbar({
            message:
              literals.SOME_OF_THE_SELECTED_TIME_ENTRIES_WERE_POSTED_SUCCESSFULLY,
            severity: literals.ERROR,
            width: '75%',
          })
        );
        console.log(e);
      });
  };

  const resetAutoSaveStorage = () => {
    clearInterval(autoSaveIntervalRef.current); // time interval
    localStorage.removeItem('autoSave');
  };

  const handleCancelRowModal = () => {
    let updatedRow = rows.find((r) => r?.id == editRowId);

    // Newly added row with no data
    if (
      jsUtils.checkIfObjectsEqual(selectedRow, updatedRow) &&
      selectedRow?.isNew
    ) {
      let all_rows = [...rows];
      // update states
      all_rows = all_rows.filter((r) => r.id != selectedRow.id);
      setRows(all_rows);
      setRowModesModel({
        ...rowModesModel,
        [updatedRow.id]: { mode: GridRowModes.View },
      });
      setEditRowId(null);
      setSelectedRow(null);
    } else {
      //Open the Alert dialog for cancel button
      setModal({
        open: true,
        title: literals.DISCARD_TIME_ENTRY,
        message:
          literals.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_TIME_ENTRY,
        cancelLabel: literals.GO_BACK,
        discardLabel: literals.DISCARD,
        confirmLabel: '',
        onCancel: onCancelModal,
        onConfirm: '',
        onDiscard: onDiscardModal,
        ModalType: literals.TWO_ACTIONS,
        isCancel: true,
      });
    }
  };

  const onDiscardModal = (ModalType) => {
    const editedRow = timeEntries.find(
      (entry) => entry.id === editRowId
    )
    if (ModalType === literals.TWO_ACTIONS) {
      const freshCopy = editedRow ? { ...editedRow } : null;
      let updatedRows = [];

      if (!freshCopy) {
        // empty newly added row
        updatedRows = rows.slice(1);
      } else {
        updatedRows = rows.map((row) =>
          row.id === editRowId ? freshCopy : row
        );
      }

      setRows([...updatedRows]);
      setColumnValues((prevValues) => ({
        ...prevValues,
        [editRowId]: freshCopy,
      }));
      onCancelModal();
    }
    // setRowModesModel({});
    if(editRowId && editedRow){
      setRowModesModel({...rowModesModel,[editRowId]:{ mode:GridRowModes.View }});
    }
    else{
      setRowModesModel({});
    }
    setEditRowId(null);
  };

  const handleClickAway = () => {
    //  setRowModesModel({});
    clearInterval(autoSaveIntervalRef.current);
  };

  const setSelectedOption = () => {};

  const handleColumnResize = useCallback((params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  }, []);

  const onTimerClicked = (id, action) => {
    if (action === literals.STARTED || action === literals.STOPPED) {
      setTimersActive([...timersActive, id]);
    } else {
      let filteredTimerList = timersActive?.filter(
        (timerId) => timerId !== id
      );
      setTimersActive(filteredTimerList);
    }
  };

  const getSuggestionsFunction = (searchText,Id,params) => {
    const editedRow = rows.find(
      (entry) => entry.id === editRowId
    )
    switch (params.field) {
      case 'clientMattersDescription':
      case 'matterName':
        return timeEntryService.searchClientMatters(searchText);
      case 'timekeeper':
        return timeEntryService.getTimeKeepers(searchText,editedRow?.customerId);
      case 'taskCode':
        return timeEntryService.searchTaskCode(searchText,editedRow?.customerId);
      case 'activity':
        return timeEntryService.searchActivityCode(searchText,editedRow?.customerId);
      case 'location':
        return timeEntryService.searchLocations(searchText,editedRow?.customerId);
      default:
        return new Promise(() => {});
    }
  };

  const getGridColumns = React.useMemo(() => {
    // logic to generate columns dynamically
    return getColumns(
      columnsDef,
      columnWidths,
      handleAddtoHours,
      getRowsValues,
      setOptionsChanges,
      errors,
      setSelectedOption,
      OptionDescription,
      handleCellValueChange,
      OptionTimekeeperDescription,
      entryTypeList,
      handleDate,
      handleHours,
      handleQuantity,
      handleAmountEdit,
      handleCancelRowModal,
      handleonSubmit,
      handleEditClick,
      handleDeleteClick,
      timersActive,
      onTimerClicked,
      getSuggestionsFunction
    );
  }, [
    rowModesModel,
    editRowId,
    entryTypeList,
    JSON.parse(JSON.stringify(rows)),
    timersActive,
  ]);

  const handleCellNavigationKeyDown = (params, event) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      const rowId = params.id;
      const rowElement = document.querySelector(
        `[data-id="${rowId}"]`
      );
      if (!rowElement) return;

      const focusableElements = Array.from(
        rowElement.querySelectorAll(
          'input, button, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
      ).filter((el) => !el.disabled);

      const currentIndex = focusableElements.indexOf(
        document.activeElement
      );
      let nextIndex;

      if (event.shiftKey) {
        nextIndex =
          currentIndex > 0
            ? currentIndex - 1
            : focusableElements.length - 1;
      } else {
        nextIndex =
          currentIndex < focusableElements.length - 1
            ? currentIndex + 1
            : 0;
      }

      const nextElement = focusableElements[nextIndex];
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  return (
    <ApolloContextProvider uri="/time-management/graphql">
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
              display: 'flex',
              gap: 2,
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
          ref={DataGridRef}
          className="timeEntryContainer"
        >
          <DataGridPro
            rows={filteredRows}
            columns={getGridColumns}
            ref={ref}
            onCellKeyDown={handleCellNavigationKeyDown}
            editMode="row"
            rowCount={filteredRows.length}
            loading={isLoading}
            // onCellKeyDown={handleKeyDown}
            paginationMode="server"
            onPaginationModelChange={(model) => {
              setisLoading(true);
              dispatch(
                fetchTimeEntryList({
                  page: model.page,
                  size: model.pageSize,
                })
              )
                .then((data) => {
                  setisLoading(false);
                })
                .catch((error) => {
                  dispatch(
                    openSnackbar({
                      message: literals.SOMETHING_WENT_WRONG,
                      severity: literals.ERROR,
                    })
                  );
                  setisLoading(false);
                });
            }}
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            disableColumnMenu={true} // to disable column menu
            checkboxSelection // enable checkbox selection
            isRowSelectable={(params) =>
              params.row.status !== literals.POSTED
            }
            disableRowSelectionOnClick // disable row selection on click
            isCellEditable={(params) =>
              params.row.status !== literals.POSTED
            } // disable row editing if status is "posted"
            onRowDoubleClick={(params) => {
              if (
                !editRowId &&
                params.row.status !== literals.POSTED
              ) {
                // If row is not already in edit mode and the there should not be any other row currently being edited.
                handleRowEditStart(params);
              } else {
                editRowId !== params.row.id &&
                  params.row.status !== literals.POSTED &&
                  dispatch(
                    openSnackbar({
                      message: 'Edit already in progress',
                      severity: literals.ERROR,
                    })
                  );
              }
            }}
            onRowClick={(params) => handleRowModes(params)}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            getRowClassName={(params) =>
              rowModesModel[params.id]?.mode == GridRowModes.Edit &&
              'row-editing'
            }
            rowSelectionModel={rowSelectionModel}
            slotProps={{
              cell: { tabIndex: 0 },
              toolbar: {
                handleColumnVisibilityChange,
                timeColumns,
                DataGridRef,
                setRows,
                setRowModesModel,
                rowSelectionModel,
                rows,
                postMultipleRows,
                setFilteredRows,
                startAutoSaveTimer,
                handleDeleteClick,
                removeWidgetFromDashboard,
                widgetId,
                fetchEntries,
                setEditRowId,
                editRowId
              },
              pagination: {
                page,
                totalPages,
              },
            }}
            slots={{
              //custom Toolbar
              toolbar: CustomGridToolBar,
              loadingOverlay: LinearProgress,
              pagination: CustomPaginationTimeEntryGrid,
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            pagination={true}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 100, page: 0 },
              },
            }}
            onColumnResize={handleColumnResize}
          />
        </Box>
      </ClickAwayListener>
      {Modal !== null && <AlertDialog {...Modal} />}

      {/* Time Entry Edit Modal */}
      <TimeEntryModal
        open={open}
        isOpenFromModel={true}
        handleClose={handleEditModalClose}
        selectedTimeEntry={selectedTimeEntry}
        previousTime={previousTime}
      />
    </ApolloContextProvider>
  );
};

export default memo(TimeEntryGrid);
